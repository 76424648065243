import { defineComponent } from '@vue/composition-api';
import { ref } from 'vue';
import { WX_CONFIG } from '../../http/api';
import { Toast } from 'vant';
export default defineComponent({
  setup() {
    const imgw = ref(0);
    const imgh = ref(0);
    const imgSrc = ref("");
    const subText = ref("");
    WX_CONFIG({}).then(res => {
      Toast.clear();
      imgSrc.value = res.data.oa_img;
      imgw.value = res.data.img_w;
      imgh.value = res.data.img_h;
      subText.value = res.data.subscribe_text;
    });
    return {
      imgw,
      imgh,
      imgSrc,
      subText
    };
  }

});