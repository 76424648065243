import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, _toDisplayString(_ctx.subText), 1), _createElementVNode("div", null, [_createVNode(_component_van_image, {
    width: _ctx.imgw,
    height: _ctx.imgh,
    src: _ctx.imgSrc
  }, null, 8, ["width", "height", "src"])])]);
}